import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Toggle from '../../components/ui/Toggle';

const IndexPage = () => (
  <Layout rtl>
    <SEO title="الشحن والتوصيل الشحن والتوصيل" />
    <h2>الشحن والتوصيل الشحن والتوصيل</h2>
    <p>نسعى في علامة ليم إلى ضمان تجربة تسوق سلسلة ومريحة لكم دائماً. ونقدم أدناه جميع المعلومات التي تحتاجون إليها حول توصيل طلباتكم.</p>
    <Toggle title="ما هي المناطق التي يمكن توصيل الطلبات إليها داخل المملكة العربية السعودية؟">
      <p>يمكننا توصيل الطلبات إلى جميع أنحاء المملكة العربية السعودية</p>
    </Toggle>
    <Toggle title="ما نوع خدمة الشحن التي تقدمونها وكم تبلغ تكلفتها؟">
      <p>نحن نقدم خدمة توصيل مجانية لجميع طلبات التوصيل القياسية.</p>
      <p> يتم تحصيل رسوم الخدمات المميزة مثل خدمات التوصيل في اليوم نفسه أو اليوم التالي</p>
      <table>
        <tr>
          <td>خدمة التوصيل في اليوم نفسه</td>
          <td>خدمة التوصيل في اليوم التالي</td>
          <td>خدمة التوصيل القياسية</td>
        </tr>
        <tr>
          <td>50 ريال سعودي</td>
          <td>20 ريال سعودي</td>
          <td>مجاناً</td>
        </tr>
      </table>
    </Toggle>
    <Toggle title="ما هي شركة الشحن السريع التي يتعاون معها متجر ليم؟ ">
      <p>نسعى في متجر ليم إلى ضمان توصيل طلباتكم بأقصى قدر ممكن من الكفاءة. لذا، تعاونا مع عدد من أفضل الشركات الرائدة عالمياً فى مجال الشحن السريع. سيتم توصيل طلبكم بواسطة شركة دي إتش إل أو سمسا اكسبريس</p>
    </Toggle>
    <Toggle title="كم يستغرق توصيل طلبي؟">
      <p>ستستلمون طلبكم عادةً خلال فترة تتراوح بين يوم وثلاثة أيام عمل اعتباراً من تاريخ الطلب. في حال اختيار خدمة توصيل ممتازة، سيتم شحن الطلب لضمان توصيله في الوقت المتوقع.</p>
    </Toggle>
    <Toggle title="كيف يمكنني معرفة حالة طلبي؟">
      <p>سنرسل لكم رسالة إلكترونية نعلمكم فيها بشحن طلبكم. وستتواصل معكم شركة الشحن السريع الشريكة لنا لترتيب الوقت الأنسب لتوصيل الطلب.</p>
    </Toggle>
    <Toggle title="هل يمكنني تغيير عنوان الشحن الخاص بي بعد تقديم الطلب؟">
      <p>نعم، يمكنكم ذلك، وما عليكم سوى إبلاغ شركة الشحن السريع الشريكة لنا أو الاتصال بنا قبل شحن طلبكم.</p>
    </Toggle>
    <Toggle title="هل يتعين علي إبراز أي وثيقة عند الشحن؟">
      <p>قد نطلب منكم إبراز هويتكم الشخصية، مثل بطاقة الهوية الوطنية أو جواز السفر</p>
    </Toggle>
    <h2>سياسة الترجيع،</h2>
    <p>نأمل أن تكون مشترياتكم من ليم قد نالت إعجابكم، وفي حال غيرتم رأيكم، إليكم الخطوات التي يجب اتباعها لترجيع المشتريات واسترداد المبالغ المدفوعة.</p>
    <Toggle title="ما هي المدة التي يمكنني خلالها ترجيع المشتريات؟">
      <p>يحق للعميل ترجيع المشتريات في فترة لا تتجاوز 15 يوم ابتداءً من تاريخ توصيل أو استلام الطلب، بشرط استيفاء شروط سياسة الترجيع لدينا</p>
    </Toggle>
    <Toggle title="ما هي سياسة ترجيع المشتريات لديكم؟">
      <p>يسعدنا رد المبلغ المدفوع لأي قطعة طالما أنها مصحوبة بفاتورة الضريبة وبطاقة الترجيع
        يجب أن تكون القطعة المرتجعة في حالتها الأصلية وغير مستعملة، كما يجب أن تكون في غلافها الأصلي مع وجود بطاقات تعريف المنتج.
        سيتم رد المال عبر وسيلة الدفع نفسها.
      </p>
    </Toggle>
    <Toggle title="هل هناك استثناءات لهذه السياسة؟">
      <p>نعم، ومن بينها المجوهرات المستخدمة في ثقب الجلد ومستحضرات التجميل</p>
    </Toggle>
    <Toggle title="كيف يمكنني ترجيع مشترياتي؟">
      <p>حرصنا على تبسيط عملية ترجيع قدر الإمكان، حيث يمكن ترجيع المشتريات إلى أي من متاجرنا في المملكة العربية السعودية، ويمكن استخدام بطاقة الترجيع وترتيب عملية التسليم مع إحدى شركات التوصيل الشريكة.
        لترجيع المشتريات عبر إحدى شركات التوصيل، يرجى استخدام البطاقة مسبقة الدفع المرفقة مع عملية التوصيل.
        التواصل مع جهة التوصيل لترتيب عملية التسليم
        يرجى ملء بطاقة الترجيع المُرفقة مع الطرد، وإيضاح سبب الترجيع لمساعدتنا في معالجة طلبكم.
        تسليم المرتجع إلى جهة التوصيل.
        بمجرد استلام المرتجع، ستتلقون رسالة تأكيد عبر البريد الإلكتروني
      </p>
    </Toggle>
    <Toggle title="ماذا لو تمت عملية الشراء من خلال بطاقة الخصم أو البطاقة الائتمانية؟">
      <p>قد تستغرق العملية بأكملها ثلاثة أسابيع ابتداءً من يوم استلام القطع المرتجعة، حيث ستتلقون إشعاراً عند معالجة طلب الترجيع، وسيتم رد المال إلى بطاقة الدفع الأصلية.</p>
    </Toggle>
    <Toggle title="دفعت نقداً عند التسليم؛ كيف يمكنني استرداد قيمة المشتريات؟">
      <p>يمكن استرداد المبلغ المدفوع نقداً عند عملية التسليم بطريقتين، إما عبر تحويله إلى حسابكم البنكي بعد ملء التفاصيل اللازمة في نموذج الاسترداد الذي أرسلناه إلى بريدكم الإلكتروني، أو استرداده نقداً بعد ترجيع المشتريات إلى أحد متاجرنا في المملكة العربية السعودية
        رابط لمحدد موقع المتاجر في التطبيق
      </p>
    </Toggle>
    <Toggle title="هل سيتم استرداد ضريبة القيمة المضافة؟">
      <p>نعم، سيتم رد قيمة المشتريات مع ضريبة القيمة المضافة</p>
    </Toggle>
    <Toggle title="هل سيتم تعويضي عن تكاليف التوصيل؟">
      <p>في معظم الحالات، لن يتم دفع رسوم عملية التوصيل، لمزيد من المعلومات حول هذه النقطة، يرجى التواصل معنا على الرقم <span dir="ltr">800 440 0099</span>، أو عبر البريد الإلكتروني customercare@leem.com</p>
    </Toggle>
  </Layout>
);

export default IndexPage
